.admin-breadcrumb {
    display: flex;
    margin: 16px;
}

.admin-breadcrumb div::before {
    content: "→ ";
}

.admin-breadcrumb div:first-child::before {
    content: "";
}