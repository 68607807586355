.refreshGraphButton {
    position: absolute;
    top: 20px;
    left: 450px;
    border:none;
    background:none;
}

.refreshGraphButton {
    position: absolute;
    top: 20px;
    left: 450px;
    border:none;
}

.rotate-center{-webkit-animation:rotate-center .6s ease-in-out both;animation:rotate-center .6s ease-in-out both}

@-webkit-keyframes rotate-center{0%{-webkit-transform:rotate(0);transform:rotate(0)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes rotate-center{0%{-webkit-transform:rotate(0);transform:rotate(0)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}