.home-body {
    position: absolute;
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: #F0F0F3;
    z-index: 9;
}

.home-title {
    padding-left: 40px;
    margin: 16px 16px 0px 16px;
    color: #6371B0;
    font-size: 1.8em;
    font-weight: 600;
}

.home-content {
    padding-left: 40px;
    margin: 32px 16px 0px 16px;
}