.graph_container {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  background: #eeeef2;
  z-index: -1;
}
.graph_container > div {
  margin-left: auto;
  margin-right: auto;
}
.graph_container .loader {
  top: 50%;
  position: relative;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
}

.graph_container .error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.graph_container .error h3{
  text-align: center;
}

.top_controls_container {
  padding: 5px;
}

.rocket {
  height: 35px;
}
.bottom_controls_container {
  bottom: 5px;
  right: 5px;
  padding: 5px;
  position: absolute;
  float: right;
}

.bottom_controls_container > div {
  float: right;
}
.unit {
  padding: 1px;
  background: white;
  border-radius: 5px;
  filter: drop-shadow(0 0 0.25rem rgba(128, 128, 128, 0.47));
}
.main_container {
  position: absolute;
  /* width: 100vw; */
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.bottom_controls_rectangle {
  padding: 5px;
  background: #71788414 0% 0% no-repeat padding-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.plus_minus_button {
  height: 30px;
  width: 30px;
  padding: 0px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #71788429;
  border-color: #FFFFFF;
  border-radius: 4px;
  color: #21252A;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.zoom_value {
  margin-left: 8px;
  margin-right: 8px;
  color: #21252A;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.loading-spin {
  z-index: 10;
}

.loading-spin .loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform:translate(-50%,-50%);
}

.loading-spin::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #e8e8e894;
  border-radius: 15px;
  box-shadow: #bebebe94 0px 0px 8px 4px;
}