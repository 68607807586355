.flashingIcon {

    opacity: 1;
    width: 20px;
    animation: blinker 1s linear infinite;
}

.syncMoodle {
    width: auto;
    font-weight: 700;
    color: #5768ac;
    display: inline-block;
    margin-right: 15px;
}

.flashingErrorIcon {
    opacity: 1;
    width: 20px;
    animation: blinker 3s linear infinite;
}
.show {
    display: block;
}

.hide {
    display: none;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.container {
    position: absolute;
    top: 22px;
    left: 660px;
}
