.helpObjectives-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 0;
    height: 100vh;
    display: block;
    right: 0;
    transition: all 0.5s ease-in-out;
    /* left: calc(100vw); */
    left: calc(100%);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px #71788429;
    opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    z-index: 1001;
}
.helpObjectives-modal.helpObjectives-open {
    width: 350px;
    transition: all 0.5s ease-in-out;
    /* left: calc(100vw - 350px); */
    left: calc(100% - 350px);
    z-index: 1001;
}

.helpObjectives-header {
    height: 112px;
    width: 350px;
    padding: 32px;
}

.helpObjectives-title {
    width: 260px;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 500;
    color: #21252A;
    margin-bottom: 0px;
}

.helpObjectives-close {
    cursor: pointer;
    left: 300px;
    position: absolute;
    top: 25px;
    color: rgba(0, 0, 0, 0.36);
}

.helpObjectives-intro {
    width: 350px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 32px;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #717884;
}

.helpObjectives-body {
    width: 316px;
    margin-right: 17px;
    margin-left: 17px;
}

.helpObjectives-bold {
    font-weight: 700;  
}

.helpObjectives-section {
    width: 316px;
}

.helpObjectives-section-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 12px;
    color: #717884;
}

.helpObjectives-section-objective {
    position: relative;
    width: 300px;
    height: 101px;
    border-radius: 4px;
    margin-top: 12px;
    margin-bottom: 24px;
    /* background: rgb(199, 201, 206) 0% 0% no-repeat padding-box; */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 12px #00000014;
    display: flex;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #717884;
}

.helpObjectives-section-objective-name {
    height: 65px;
    width: 150px;
}

.helpObjectives-section-objective-progression {
    position: absolute;
    height: 10px;
    width: 266px;
    left: 25px;
    top: 75px;
    border-radius: 5px;
    background: black;
    opacity: 0.4;
}

.helpObjectives-section-objective-progression-text {
    position: absolute;
    height: 10px;
    width: 266px;
    left: 225px;
    top: 72px;
    color: white;
    font-size: 10px;
    font-weight: 700;
}

.helpObjectives-intro-nodes {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 32px;
}

.helpObjectives-section-status {
    width: 316px;
    margin-left: 3px;
}

.helpObjectives-section-status-items {
    display: flex;
    align-items: center;
}

.helpObjectives-status {
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: #717884;
}