.sidebar {
  position: relative;
  display: block;
  height: 100%;
  z-index: 10;
  color: #FFFFFF;
  background: #5364a5;
  box-shadow: 2px 0 3px rgb(159 162 191 / 18%), 1px 0 1px rgb(159 162 191 / 32%);
  transition: all 0.2s;
}
.sidebar-full {
  width: 280px;
}
.sidebar-collapse {
  width: 83px;
}

.sidebar-content {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.sidebar-scroll {
  position: absolute;
  width: 100%;
  inset: 0px;
  overflow: scroll;
  margin-right: -17px;
  margin-bottom: -17px;
}

.sidebar-head {
  display: flex;
  align-items: center;
  height: 88px;
  padding: 0px 16px;
}

.sidebar-head-link {
  display: flex;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  align-items: center;
  color: #FFFFFF;
  text-decoration: none;
  font-weight: 700;
  cursor: pointer;
}
.sidebar-head-link:hover {
  color: #FFFFFF;
}

.sidebar-head-logo {
  display: flex;
  justify-content: center;
  width: 30px;
  padding: 0px 4px;
}

.sidebar-head-img {
  width: 30px;
  height: 30px;
}

.sidebar-head-title {
  padding-left: 16px;
  font-size: 1rem;
  font-weight: 700;
  white-space: nowrap;
}

.sidebar-list {
  position: relative;
  width: 100%;
  margin-bottom: 8px;
}
.sidebar-list-collapser {
  position: absolute;
  bottom: 16px;
}

.sidebar-list-link{
  text-decoration: none;
}

.sidebar-list-title {
  padding: 6.4px 16px;
  margin-bottom: 8px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem;
  color: #FFFFFF;
  line-height: 1.4;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sidebar-list-element {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2px 16px;
  color: #FFFFFF;
}

.sidebar-collapser-element-link {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px 10px;
  font-weight: bold;
  text-transform: none;
  background-color: transparent;
  font-size: 0.8666666666666667rem;
  text-decoration: none;
  cursor: pointer;
}

.sidebar-list-element-link-label {
  margin-left: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.list-delimiter {
  width: 100%;
  border-bottom: 2px solid #ffffff;
  margin-top: 15px;
  margin-bottom: 15px;
}