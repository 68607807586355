.navigationMenu-content {
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;
    top: 8px;
    left: 8px;
    height: 56px;
    padding: 9px 12px 9px 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #00000014;
    border-radius: 4px;
}
.navigationMenu-content-fully-opened {
    width: 638px;
}
.navigationMenu-content-partially-opened {
    width: 500px;
}

@media(max-width:850px) {
    .navigationMenu-content  {
        width: 420px;
    }
}

.helpNotifUserMenu-Btn:hover {
    cursor: pointer;
}

.navigationMenu-logo {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 16px;
    margin-right: 12px;
    border-right: 1px solid #dee2e6;
}

.navigationMenu-image {
    width: 30px;
    margin-right: 10px;
}

@media(max-width:850px) {
    .navigationMenu-logo  {
        width: 88px;
    }
}

.navigationMenu-image {
    width: 45px;
    height: 45spx;
}

.navigationMenu-title {
    margin-left: 8px;
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
    font-weight: 700;
}

@media(max-width:850px) {
    .navigationMenu-title {
        display: none;
    }
}

.navigationMenu-display {
    width: 380px;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navigationMenu-course {
    width: 80px;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #C7C9CE;
    padding-left: 12px;
}

@media(max-width:850px) {
    .navigationMenu-course {
        display: none;
    }
}

.navigationMenu-list {
    display: flex;
    align-items: center;
    width: 300px;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #717884;
    padding-left: 12px;
    cursor: pointer;
}

.navigationMenu-list-title {
    width: 262px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.bi-chevron-down {
    margin-left: 12px;
}

.courseList-modal {
    width: 184px;
    height: var(--h);
    left: 400px;
    top: 50px;
    display: none;
    position: absolute;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #00000014;
    border-radius: 4px;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #21252A;
    z-index: 1;
}
.courseList-modal.courseList-open {
    display: flex;
}

@media(max-width:850px) {
    .courseList-modal {
        left: 140px;
    }
}

.navigationMenuSelect {
    cursor: pointer;
    padding-left: 14px 12px 12px;
    padding-left: 12px;
    padding-right: 32px;
}
.navigationMenuSelect:first-of-type {
    margin-top: 14px;
}
.navigationMenuSelect:last-of-type {
    margin-bottom: 12px;
}
.navigationMenuSelect:hover {
    background: #71788429;
}
