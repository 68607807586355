.admin {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
}

.admin-body {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    flex: 1 1 auto;
    padding-left: 0px;
    background-color: #F0F0F3;
}

.admin-content {
    position: absolute;
    height: 100%;
    width: 100%;
    flex: 1 1 auto;
    overflow: auto;
}

.admin-homepage-title {
    margin: 24px;
}