.contributory-activities-title-link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.objective-edit-panel-contributory-activities .rdt_TableHeader {
  color: #33CA7F;
}

.objective-edit-panel-contributory-activities .rdt_TableHead {
  background-color: #33CA7F;
}