.student-profile {
  margin: 32px;
}

.student-profile-title {
  color: #6371B0;
  font-size: 1.8em;
  font-weight: 600;
}

.student-profile-content {
  margin-top: 32px;
  display: flex;
}

.student-profile-left {
  width: 60%;
  padding-right: 42px;
}

.student-profile-right {
  width: 40%;
  padding-left: 42px;
}

.student-profile-subtitle {
  font-size: 1.5em;
  font-weight: 500;
  color: #717884;
}



.student-profile-contributions-counter {
  margin-top: 16px;
}

.student-profile-contributions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.student-profile-contributions>.student-profile-activity-box {
  width: 48%;
  margin-top: 32px;
  margin-bottom: 32px;
}



.student-profile-activity {
  margin-top: 32px;
  margin-bottom: 32px;
}

.student-profile-activity-info:not(:last-child) {
  margin-bottom: 16px;
}




.student-profile-recommendations {
  overflow: scroll;
  max-height: 800px;
}

.student-profile-recommendation-box {
  padding: 32px;
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #E1E1E3 0% 0% no-repeat padding-box;
  border-radius: 8px;
}

.student-profile-recommendation-bubble {
  width: 80%;
  position: relative;
  padding: 24px;
  margin-bottom: 16px;
  border-radius: 40px;
  font-family: sans-serif;
  font-size: 18px;
  line-height: 24px;
  background: #fff;
  text-align: center;
  color: #6C757D;
}

.student-profile-recommendation-bubble:before {
  width: 0px;
  height: 0px;
  left: 32px;
  bottom: -24px;
  position: absolute;
  border-left: 24px solid #fff;
  border-right: 12px solid transparent;
  border-top: 12px solid #fff;
  border-bottom: 20px solid transparent;
  content: "";
}

.student-profile-recommendation-box>.student-profile-activity-box {
  width: 100%;
}

.student-profile-recommendation-box>.student-profile-activity-box:not(:first-child) {
  margin-top: 32px;
}