.PopUpValidation {
    position: fixed;
    z-index: 99999;
    top: 50%;
    left: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
}

.PopUpContent {
    background-color: white;
    padding: 35px;
    border-radius: 10px;
}

.PopUpValidation h3{
    margin-bottom: 35px;
}

.PopUpValidation button {
    margin-right: 15px;
}

.PopUpValidation:before {
    content: "";
    position: absolute;
    width: 100vw;
    z-index: -13;
    height: 100vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #8080803d;
}

.spinner-border {
    margin: auto;
    display: block;
    margin-bottom: 15px;
}