.activity-edit-panel-contributive-form-form {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100% - 240px);
  margin: 24px;
}

.activity-edit-panel-contributive-form-asking {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.activity-edit-panel-contributive-form-asking:not(:first-child) {
  margin-top: 32px;
}

.activity-edit-panel-contributive-form-asking-information {
  width: 92%;
  cursor: pointer;
}

.activity-edit-panel-contributive-form-asking-categorie-select__control {
  width: 180px;
}

.activity-edit-panel-contributive-form-quiz {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 32px;
  cursor: pointer;
}

.activity-edit-panel-contributive-form-quiz-mark {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.activity-edit-panel-contributive-form-quiz-mark-input {
  border: 1px solid #71788466;
  border-radius: 4px;
  max-width: 60px;
}

.activity-edit-panel-contributive-form-quiz-mark-no-completion {
  cursor: default;
}

.activity-edit-panel-contributive-form-quiz-mark-no-completion::before {
  content: "⚠ ";
}

.activity-edit-panel-contributive-form-errors {
  height: 21px;
  margin-top: 8px;
  color: #bf1650;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.08px;
}

.activity-edit-panel-contributive-form-errors span::before {
  content: "⚠ ";
}

.activity-edit-panel-student-contribution {
  margin-top: 32px;
}

.activity-edit-panel-student-contribution-message {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 16px;
}

.activity-edit-panel-student-contribution-message-warning::before {
  content: "⚠ ";
}

.activity-edit-panel-student-contribution-button {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.activity-edit-panel-student-contribution-button svg,
.activity-edit-panel-student-contribution-button path {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.activity-edit-panel-student-contribution-button svg:hover,
.activity-edit-panel-student-contribution-button svg:hover path {
  fill: #5569ff;
  transition: all 0.2s;
}

.activity-edit-panel-contributive-form-submit {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 32px;
}

.activity-edit-panel-contributive-form-submit-button {
  width: 100%;
}

.activity-edit-panel-contributive-form-submit-button {
  background: #0a58ca 0% 0% no-repeat padding-box;
}