.modal {
  position: absolute;
  width: 0;
  height: 100vh;
  background: white;
  display: block;
  right: 0;
  transition: all 0.5s ease-in-out;
  /* left: calc(100vw); */
  left: calc(100%);
  word-break: break-word;
}
.modal.open {
  width: 350px;
  transition: all 0.5s ease-in-out;
  /* left: calc(100vw - 350px); */
  left: calc(100% - 350px);
}

.modal .header {
  min-height: 170px;
  padding-bottom: 35px;
  width: 350px;
  position: relative;
  background:#717884 ;
}

.modal .icon {
  position: absolute;
  right: 40px;
  bottom:-25px;
  width: 50px;
  height: 50px;
}
.modal .shield {
  position: absolute;
  right: 40px;
  bottom:-25px;
  width: 60px;
  height: 60px;
}
.modal .body {
  padding: 25px 25px;
  width: 350px;
  color: #8b909b;
  font-size: 14px;
  height: calc(100vh - 260px );
}
.modal .body.activity {
  padding: 30px 15px;
  height: calc(100vh - 350px );
}
.modal .header .close {
  cursor: pointer;
  left: 300px;
  position: relative;
  top: 25px;
  color: rgba(0, 0, 0, 0.36);
}
.modal .body .advancement {
  padding: 15px 10px;
  font-size: 10px;
  height: 100%;
}
.modal .body .relatedNodes .icons {
  width: 50px;
  height: 50px;
  position: center;
}
.modal .body .relatedNodes .relatedNode {
  display: inline-grid;
  text-align: center;
  cursor: pointer;
}
.modal .title {
  padding-left: 25px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  width:77%;
}
.modal .type {
margin-top: 15px;
  padding-left: 25px;
  color: white;
  font-size: 12px;
  max-width: 273px;
  text-overflow: ellipsis;
  text-align: justify;
  max-height: 104px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.modal .creator {
  margin-top: 15px;
  padding-left: 25px;
  color: rgb(202, 235, 254);
  font-size: 12px;
  max-width: 273px;
  text-overflow: ellipsis;
  text-align: justify;
  max-height: 104px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.modal .rating {
  height: 50px;
  color: #1e9b7e;
  font-size: 35px;
  font-weight: bolder;
}
.modal .carded {
  padding: 5px;
  background: #f8e9cb;
  border-radius: 5px;
  text-align: center;
}

.modal .carded .percent {
  font-size: 35px;
  color: #e6b049;
}

.modal .carded.blue {
  background: #cbecff;
  font-size: 10px;
  font-weight: bold;
  color: #47bbfe;
  margin-left: 5px;
}
.modal .carded.yellow {
  margin-right: 5px;
  margin-left: 10px;
}
.modal .carded .title {
  padding-left: 0px;
  color: #e6b049;
  font-size: 11px;
  font-weight: bold;
}
.modal .ratingContainer {
  text-align: center;
}
.modal .ratingContainer .rightContent {
  border-left: 1px solid #d0d0d0;
}
.modal .spaced {
  margin-top: 5px;
  margin-bottom: 10px;
}
.modal .btn {
  width: 100%;
  font-size: 15px;
}

.modal .primary {
  width: 100%;
  background: #0a58ca;
}
.modal .noBG {
  width: 100%;
  border: none;
  background: white;
  color: #0a58ca;
}

.modal .second {
  width: 100%;
  border: 1px solid #0a58ca;
  background: white;
  color: #0a58ca;
}
.modal .buttons {
  position: absolute;
  width: 325px;
  bottom: 15px;
}
.modal .single-line{
  overflow: hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  width: 90%;
  display:inline-block;
}


.scrollbox {
  background:
    /* Shadow covers */
          linear-gradient(white 30%, rgba(255,255,255,0)),
          linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,

            /* Shadows */
          radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
          radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}

.scrollbox-activities {
  height: 95%;
  overflow: hidden;
  overflow-y: auto;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}

.scrollbox-activities h6 {
  margin-top:15px;
  margin-bottom:15px;
}
.relatedNode{
  cursor: pointer;
}

.noteQuiz {
  width: 25%;
  height: 35px;
  padding: 5px;
  padding-right: 15px;
  border-radius: 5px;
  border: 1px solid black;
  padding-left: 10px;
  font-size: 15px;
  margin: 0;
  box-sizing: border-box;
  margin-bottom: 5px;
  display: inline-block;
}

.buttonEndQuiz {
  margin-left: 5%;
  width: 70%!important;
  display: inline-block;
  margin-bottom: 5px;
}