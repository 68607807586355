.student-profile-activity-box {
  max-height: 142px;
  padding: 16px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #21252A14;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 60px 1fr 1fr 1fr 1fr;
  grid-template-rows: 30px 30px 50px;
}

.student-profile-activity-box-info {
  letter-spacing: 0.99px;
  font-size: 0.8em;
  color: #6C757D;
}

.student-profile-activity-box-logo {
  grid-area: 1 / 1 / 3 / 2;
}

.student-profile-activity-box-course {
  grid-area: 2 / 2 / 3 / 6;
}

.student-profile-activity-box-type {
  grid-area: 1 / 4 / 2 / 6;
  text-align: right;
}

.student-profile-activity-box-status {
  grid-area: 1 / 2 / 2 / 4;
}

.student-profile-activity-box-title {
  grid-area: 3 / 1 / 5 / 6;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 75px;
}