.simulateurRecommandation {
    position: fixed;
    right: 177px;
    bottom: 10px;
    max-height: 40px;
    overflow: hidden;
    padding: 0px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px #71788429;
    border-color: #FFFFFF;
    border-radius: 4px;
    color: #21252A;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding-left: 15px;
    padding-right: 15px;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    width: 300px;
    box-sizing: content-box;
}

.simulateurRecommandation.open {
    max-height: 800px;
    transition: max-height 1s ease-in-out;
    padding-bottom: 25px;
}

.simulateurRecommandation.openResult {
    width: 800px!important;
}

.simulateurRecommandation .bi-chevron-down {
    float: right;
    height: 40px;
}

.simulateurRecommandation.open .bi-chevron-down {
    transform: rotate(180deg);
    transition: all 0.2s;
}

.bi-chevron-down {
    text-align: right;
    transition: all 0.2s;
}

.simulateurRecommandationBtn {
    background: none;
    border: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 19px;
    font-weight: 500;
    color: #717884;
    cursor: pointer;
    height: 45px;
    padding: 0;
    line-height: 40px;
    margin-bottom: 15px;
    border-bottom: 1px solid #71788466;
    width: 100%;
}

.simulateurRecommandationBtn span {
    float: left;
}

.simulateurRecommandation form  {
    display: inline-block;
    vertical-align: top;
    width: 300px;
}

.simulateurRecommandation form label {
    display: block;
    margin-bottom: 5px;
}
.simulateurRecommandation form input {
    display: block;
    padding-left: 12px;
    margin-bottom: 20px;
    width: 100%;
    border: 1px solid #71788466;
    height: 35px;
    border-radius: 5px;
    outline-color: #2684FF;
}

.simulateurRecommandation form .basic-multi-select {
    display: block;
    margin-bottom: 20px;
}

.simulateurRecommandation .objective-edit-panel-form-domaine-select__control {
    align-items: flex-start !important;
    border: 1px solid #71788466;
    height: 50px;
}

.simulateurRecommandation form .tempsDisponibleCheckbox {
    display:inline-block;
    width: 10%;
    margin-right: 5%;
    margin-bottom: 0;
}
.simulateurRecommandation form .tempsDisponibleNumber {
    display:inline-block;
    width: 85%;
    vertical-align: top;
    margin-bottom: 0;
}

.simulateurRecommandation form i {
    font-size: 12px;
    display: block;
    margin-bottom: 20px;
}

.submitBtn {
    width: 100%;
    background-color: #5364a5;
}

.contentForm {
    display:flex;
    height: auto;
    align-items: stretch;
}


.resultRecommandation {
    width: 475px;
    border-left: 1px solid #cbcbcb;
    margin-left: 25px;
    position: relative;
    overflow: hidden;
    max-height: 450px;
    overflow-y: scroll;
}

.spinner-center {
    position: absolute;
    top:50%;
    left: 50%;
}

.recoStatus {
    position: relative;
    padding: 15px;
    background-color: #f2f2f1;
    border-radius: 8px;
    margin-left: 15px;
    margin-bottom: 15px;
}

.recoStatus li {
    margin-bottom: 5px;
}

.recoStatus h5 {
    text-decoration: underline;
}

.flag svg {
    margin-right: 5px;
}

.infoReco {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: help;
}

.recoStatus .infoReco:hover ~.infoRecoContent {
    display:block;
}

.infoRecoContent {
    display: none;
    position: absolute;
    width: 395px;
    left: 15px;
    top: 15px;
    border: 4px dashed black;
    background-color: white;
    border-radius: 7px;
    padding: 15px;
}

.objReco {
    margin-right: 5px;
}

h5.error {
    color:red;
}