.student-profile-course-box {
  padding: 24px;
  margin-top: 32px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #21252A14;
  border-radius: 8px;
}

.student-profile-course-box:last-child {
  margin-bottom: 32px;
}

.student-profile-course-box-title {
  font-size: 1.2em;
  color: #717884;
}

.student-profile-course-box-content {
  margin-top: 16px;
  display: flex;
  justify-content: space-evenly;
}

.student-profile-course-box-progress {
  width: 120px;
  height: 120px;
  color: #717884;
}

.student-profile-course-box-percent {
  font-size: 1.2em;
}