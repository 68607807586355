.activity-edit-panel-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 0;
  height: 100vh;
  display: block;
  right: 0;
  transition: all 0.5s ease-in-out;
  left: calc(100%);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #71788429;
  overflow-x: hidden;
  overflow-y: scroll;
  outline: 0;
  z-index: 1000;
}
.activity-edit-panel-modal.activity-edit-panel-open {
  width: 600px;
  transition: all 0.5s ease-in-out;
  left: calc(100% - 600px);
}

.activity-edit-panel-header {
  position: relative;
  width: 600px;
  padding: 22px 24px 32px 32px;
  z-index: 1000;
}
.activity-edit-panel-open .activity-edit-panel-header {
  background-color: #5364a5;
  transition: all 0.2s ease-in-out;
}
.activity-edit-panel-notopen .activity-edit-panel-header {
  background-color: white;
  transition: all 0.2s ease-in-out;
}

.activity-edit-panel-title {
  width: 500px;
  margin-bottom: 0px;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 500;
}
.activity-edit-panel-open .activity-edit-panel-title {
  color: white;
  transition: all 0.2s ease-in-out;
}
.activity-edit-panel-notopen .activity-edit-panel-title {
  color: #5364a5;
  transition: all 0.2s ease-in-out;
}

.activity-edit-panel-close {
  cursor: pointer;
  left: 550px;
  position: absolute;
  top: 25px;
  color: rgba(0, 0, 0, 0.36);
}

.activity-edit-panel-body {
  position: relative;
  width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
}
.activity-edit-panel-open .activity-edit-panel-body {
  background-color: white;
  transition: all 0.2s ease-in-out;
}
.activity-edit-panel-notopen .activity-edit-panel-body {
  background-color: #F0F0F3;
  transition: all 0.2s ease-in-out;
}

.activity-edit-panel-moodle-link {
  position: relative;
  bottom: 0;
  width: 504px;
  margin: 24px;
}