.objective-edit-panel-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 0;
  height: 100vh;
  display: block;
  right: 0;
  transition: all 0.5s ease-in-out;
  left: calc(100%);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #71788429;
  overflow-x: hidden;
  overflow-y: scroll;
  outline: 0;
  z-index: 1000;
}
.objective-edit-panel-modal.objective-edit-panel-open {
  width: 1050px;
  transition: all 0.5s ease-in-out;
  left: calc(100% - 1050px);
}
.objective-activity-edit-panel-open {
  width: 1050px;
  left: calc(100% - 1050px - 600px);
}

.objective-edit-panel-header {
  position: relative;
  width: 1050px;
  padding: 22px 24px 32px 32px;
  z-index: 1002;
}
.objective-edit-panel-open .objective-edit-panel-header {
  background-color: #5364a5;
  transition: all 0.2s ease-in-out;
}
.objective-activity-edit-panel-open .objective-edit-panel-header {
  background-color: white;
  transition: all 0.2s ease-in-out;
}

.objective-edit-panel-title {
  width: 950px;
  margin-bottom: 0px;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 500;
}
.objective-edit-panel-open .objective-edit-panel-title {
  color: white;
  transition: all 0.2s ease-in-out;
}
.objective-activity-edit-panel-open .objective-edit-panel-title {
  color: #5364a5;
  transition: all 0.2s ease-in-out;
}

.objective-edit-panel-close {
  cursor: pointer;
  left: 1000px;
  position: absolute;
  top: 25px;
  color: rgba(0, 0, 0, 0.36);
}

.objective-edit-panel-body {
  position: relative;
  width: 1050px;
  display: flex;
  flex-direction: column;
}
.objective-edit-panel-body-fixed {
  position: fixed;
}
.objective-edit-panel-open .objective-edit-panel-body {
  background-color: white;
  transition: all 0.2s ease-in-out;
}
.objective-activity-edit-panel-open .objective-edit-panel-body {
  background-color: #F0F0F3;
  transition: all 0.2s ease-in-out;
}

.objective-edit-panel-index {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  z-index: 9;
}

.objective-edit-panel-index-table {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  width: 100%;
  padding: 1rem;
}

.objective-edit-panel-index-table .data-table-extensions {
  display: inline-block;
  width: 30%;
  padding: 0.7rem 1.2rem;
  box-sizing: border-box;
}

.objective-edit-panel-index-table .data-table-extensions-filter {
  float: left;
  position: relative;
  display: flex;
}

.objective-edit-panel-index-table .data-table-extensions-filter .filter-text {
  width: 184px;
  padding: 4px;
  border: none;
  border: 0;
  border-bottom: 1px solid #dfdfdf;
  margin-left: 4px;
  outline: none;
  background-color: transparent;
}
.objective-edit-panel-index-table .data-table-extensions-filter > .filter-text:focus, .data-table-extensions-filter > .filter-text:hover {
  border-bottom-color: #6371B0;
}

.objective-edit-panel-index-table .data-table-extensions-filter > .icon {
  display: block;
  float: left;
  width: 20px;
  height: 24px;
  margin-top: 4px;
  fill: #6371B0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAB80lEQVQ4T73Uv2tTURQH8O85972kxpqWNG2gOKhb21X8hXYQxCEKLp3cXNw6OIm0zTWWVjo4Cv4BIlIEF4c6FEQQi4uLZNTFYvNDiwWN9713jrxKQZOXBNLiXd89H77n3Hcv4YAXHbCH/wuOWh3kKJhSEDvjVbYtbffqKDFhrqRZ1ui+IrrB4PQfRCIieorAv1Vdoq1OcBsYY4jCV6Q0aAxuS9O8TGUQhRJeUGBZoTmQf65+lzaT0DYwvxA+1AiXBsSc+bxMjb+Lxq1mnITrpFKr3Utf7QnGMxNxdQNzvVr2niUVjFh3ioU2WMITW4uHPrbu+SdhYd6dFqK32vSy9RXaSZ6T0uiCawDmZq3srXYFx+aDs0p447N3eNPSj06Dz881a2Bvtl72nnQFh60O++LqBC5Wy/5aEpizvyaN8AcRmWwspitdwfjjWMk9VqUJn73zbSln1IxMuBcMztTK/nTPQ9kF72hBvGADoC/KOtuw/juANE5GggcMvkyq6xnjFz9ZavZMGG/Il3ScxD0C8RVAvqloRGzyAF6TaqBEFwWydoRT11rRrne5MPfzuLB/UgFPJXofz+yY1YEdcc/jpEloX49DK9qopIpYpSjuri8wLtxDAUwHnDr63dLXfYG7hzGjJjuFoT1s/2DCf9N3y51u0W9RndcVBJTNUgAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: left center;
}

.objective-edit-panel-index-table .rdt_TableHeader {
  width: 70%;
  background-color: inherit;
  color: #6371B0;
}

.objective-edit-panel-index-table .rdt_Table {
  max-width: 1018px;
  border-radius: 2px;
}

.objective-edit-panel-index-table .rdt_TableHead {
  color: #FFFFFF;
  background-color: #6371B0;
}

.objective-edit-panel-index-table .rdt_TableHeadRow {
  background-color: inherit;
}

.objective-edit-panel-index-table .rdt_Pagination {
  background-color: inherit;
}

.objective-edit-panel-index-table > div:last-child {
  width: 100%;
}

.objective-edit-panel-index-loader {
  position: relative;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
  overflow-x: auto;
  overflow-y: hidden;
}