.questionForm-modal {
    position: absolute;
    --w: 760px;
    --h: 700px;
    width: var(--w);
    height: var(--h);
    left: calc(50% - calc(var(--w) / 2));
    top: calc(50% - calc(var(--h) / 2));
    display: none;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #0000001F;
    border-radius: 16px;
    z-index: 1099;
}
.questionForm-modal.questionForm-open {
    display: flex;
}

@media(max-width:850px),
      (max-height:800px) {
  .questionForm-modal {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

@media(min-height:1100px) {
  .questionForm-modal {
    --h: 900px;
  }
}

@media(min-width: 2000px) {
  .questionForm-modal {
    --w: 1000px;
  }
}

.questionForm-modal-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* Header */

.questionForm-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dee2e6;
    padding: 32px 32px 32px 40px;
}

.questionForm-header-title {
    margin-bottom: 0px;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #21252A;
}

.questionForm-close {
    cursor: pointer;
}

/* Modal body */

.questionForm-body {
    position: relative;
    flex: 1 1 auto;
    overflow: auto;
    padding: 32px 32px 32px 40px;
}
::-webkit-scrollbar {
    display: none;
}

.questionForm-section-title {
    font: normal normal medium 16px/19px Roboto;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #717884;
    margin-bottom: 16px;
}

em {
    color: #3B62FF;
}

.questionForm-section {
    margin-bottom: 32px;
}

/* Type */

.questionForm-list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0px;
}

.questionForm-list label {
    height: 48px;
    width: auto;
    padding: 12px 24px 15px;
    margin: 4px;
    border-radius: 24px;
}

.questionForm-radio {
    appearance: none;
}

.questionForm-input {
    height: 48px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #71788466;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #717884;
}
.questionForm-inputClicked {
    background: #E8F0FE 0% 0% no-repeat padding-box;
    border: 1px solid #D2E3FC;
    color: #3B62FF;
}
.questionForm-inputNoClicked {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #71788466;
    color: #717884;
}
.questionForm-input:hover {
    cursor: pointer;
    background-color: #E5E5E5;
    border: 1px solid #71788466;
    color: #717884;
}

/* Question */

.questionForm-fields {
    width: 100%;
    height: 210px;
    padding: 12px 15px 16px;
    border-radius: 4px;
    border: 1px solid #71788466;
    background: #F4F4F5 0% 0% no-repeat padding-box;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #717884;
}
.questionForm-fields:hover {
    cursor: pointer;
    background-color: #E5E5E5;
    border: 1px solid #71788466;
    color: #717884;
}

.questionForm-errors {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.08px;
    color: #bf1650;
    margin-top: 8px;
}
.questionForm-errors::before {
    content: "⚠ ";
}

.questionForm-mandatory {
    text-align: right;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #717884;
}

/* Footer */

.questionForm-footer {
    display: flex;
    justify-content: flex-end;
    padding: 32px 32px 32px 40px;
}

.questionForm-btn {
    width: 190px;
    height: 56px;
    border-radius: 4px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 500;
}

.questionForm-primary {
    background: #3B62FF 0% 0% no-repeat padding-box;
    color: #FFFFFF;
    margin-left: 16px;
    border: 0px;
    border-radius: 4px;
}

.questionForm-second {
    background: white;
    color: #3B62FF;
    margin-right: 16px;
    border: 1px solid #3B62FF;
    border-radius: 4px;
}

.questionForm-footer input[type="submit"] {
    display: none;
}