.studentSelector {
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;
    top: 8px;
    left: 445px;
    height: 56px;
    padding: 9px 12px 9px 12px;
    background: #f75555 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #00000014;
    border-radius: 4px;
    width: 200px;
}

.listStudentSelector {
    position: absolute;
    top: 60px;
    left: 0;
    width: 200px;
    height: 300px;
    overflow: hidden;
    background-color: white;
}

.modalOpen {
    max-height: 9999px;
    transition: all 0.2s;
}

.modalClose {
    max-height: 0px;
    transition: all 0.2s;
}

.listStudentSelector input {
    margin: 10px;
    box-sizing: content-box;
    width: 165px;
    height: 35px;
    border-radius: 5px;
    border: 1px solid black;
    padding-left: 10px;
    font-size: 13px;
}

.availableStudent {
    border-top: 1px solid #ededed;
    height: 265px;
    overflow: auto;
}

.listStudentSelector a  {
    color: black;
    display: block;
    width: 100%;
    text-decoration: none;
    padding: 10px;
    border-bottom: 1px solid #ededed;
    margin: 0;
}

.listStudentSelector p  {
    margin-bottom: 0;
}

.currentStudent {
    cursor: pointer;
    margin:0;
    height: 36px;
    padding: 0;
    line-height: 37px;
}

.currentStudent svg {
    margin-right: 5px;
    display: inline-block;
    width: 25px;
    margin-top: 6px;
    vertical-align: top;
}

.currentStudent .bi-chevron-down{
    margin-left: 0;
    margin-top: 11px!important;
}

.currentStudent span {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    width: 110px;
    text-overflow: ellipsis;
    font-size: 13px;
}