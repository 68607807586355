.helpActivities-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 0;
    height: 100vh;
    display: block;
    right: 0;
    transition: all 0.5s ease-in-out;
    /* left: calc(100vw); */
    left: calc(100%);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px #71788429;
    opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    z-index: 1001;
}
.helpActivities-modal.helpActivities-open {
    width: 350px;
    transition: all 0.5s ease-in-out;
    /* left: calc(100vw - 350px); */
    left: calc(100% - 350px);
    z-index: 1001;
}
.helpActivities-section-status-item.link {
    opacity:1!important;
}
.helpActivities-header {
    height: 112px;
    width: 350px;
    padding: 32px;
}

.helpActivities-title {
    width: 260px;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 500;
    color: #21252A;
    margin-bottom: 0px;
}

.helpActivities-close {
    cursor: pointer;
    left: 300px;
    position: absolute;
    top: 25px;
    color: rgba(0, 0, 0, 0.36);
}

.helpActivities-intro {
    width: 350px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 32px;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #717884;
}

.helpActivities-body {
    width: 316px;
    margin-right: 17px;
    margin-left: 17px;
}

.helpActivities-bold {
    font-weight: 700;  
}

.helpActivities-section {
    width: 316px;
}

.helpActivities-section-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 12px;
    color: #717884;
}

.helpActivities-intro-nodes {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 32px;
}

.helpActivities-section-status {
    width: 316px;
    margin-left: 3px;
    margin-bottom: 24px;
}

.helpActivities-section-status-items {
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 8px;
}

.helpActivities-status {
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: #717884;
}