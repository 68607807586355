.objective-edit-panel-form {
  position: relative;
  width: 100%;
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}
.objective-edit-panel-form > div {
  padding: 0 16px;
}
.objective-edit-panel-form-titre-court {
  grid-area: 1 / 1 / 2 / 2;
}
.objective-edit-panel-form-titre {
  grid-area: 2 / 1 / 3 / 2;
}
.objective-edit-panel-form-domaine {
  grid-area: 1 / 2 / 3 / 2;
}
.objective-edit-panel-form-description {
  grid-area: 3 / 1 / 4 / 3;
}

.objective-edit-panel-form-label {
  width: 100%;
  margin-bottom: 8px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}

.objective-edit-panel-form-label-max-length {
  font-style: italic;
}

.objective-edit-panel-form-input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #71788466;
}
.objective-edit-panel-form-input:focus-visible {
  border: 1px solid #2684FF;
  box-shadow: 0 0 0 1px #2684FF;
  outline: 0;
}

.objective-edit-panel-form-titre-court input {
  width: 100%;
}

.objective-edit-panel-form-titre input{
  width: 100%;
}

.objective-edit-panel-form-domaine {
  z-index: 1001;
}

.objective-edit-panel-form-domaine-select__control {
  align-items: flex-start !important;
  border: 1px solid #71788466;
  height: 124px;
  max-height: 124px;
}

.objective-edit-panel-form-domaine-select__value-container {
  max-height: 124px;
  overflow: scroll !important;
}

.objective-edit-panel-form-description input {
  width: 100%;
}

.objective-edit-panel-form-creation {
  width: 100%;
  margin-top: 16px;
  margin-left: 32px;
}

.objective-edit-panel-form-creation-button-submit {
  width: 400px;
  background: #0a58ca 0% 0% no-repeat padding-box;
}

.objective-edit-panel-form-creation-button-nosubmit {
  width: 400px;
}
.objective-edit-panel-form-creation-button-nosubmit:hover {
  background-color: #6c757d;
}