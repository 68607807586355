.teaching-unit-edit-title {
  margin: 16px;
  color: #6371B0;
  font-size: 1.8em;
  font-weight: 600;
}

.teaching-unit-edit-warning-message {
  color: red;
  margin-left: 16px;
}
.teaching-unit-edit-warning-message::before {
  content: "⚠ ";
}

.teaching-units-edit-objective-creation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
}

.errorList {
  margin-bottom: 30px;
}

.errorList .error {
  color:red;
}

@media(min-width: 1065px) {
  .teaching-units-edit-objective-creation-small {
    display: none;
  }
}

@media(max-width: 1065px) {
  .teaching-units-edit-objective-creation-not-in-small {
    display: none;
  }
}

@media(max-width: 1800px) {
  .teaching-units-edit-objective-creation-big {
    display: none;
  }
}