.helpLegends-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 0;
    height: 100vh;
    display: block;
    right: 0;
    transition: all 0.5s ease-in-out;
    /* left: calc(100vw); */
    left: calc(100%);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px #71788429;
    opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    z-index: 1001;
}
.helpLegends-modal.helpLegends-open {
    width: 350px;
    transition: all 0.5s ease-in-out;
    /* left: calc(100vw - 350px); */
    left: calc(100% - 350px);
    z-index: 1001;
}

.helpLegends-header {
    height: 112px;
    width: 350px;
    padding: 32px;
}

.helpLegends-title {
    width: 260px;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 500;
    color: #21252A;
    margin-bottom: 0px;
}

.helpLegends-close {
    cursor: pointer;
    left: 300px;
    position: absolute;
    top: 25px;
    color: rgba(0, 0, 0, 0.36);
}

.helpLegends-intro {
    width: 350px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 32px;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #717884;
}

.helpLegends-body {
    width: 316px;
    margin-right: 17px;
    margin-left: 17px;
}

.helpLegends-section {
    width: 316px;
    margin-bottom: 32px;
}

.helpLegends-section-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 12px;
    color: #717884;
}

.helpLegends-section-intro {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 16px;
}

.helpLegends-section-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.helpLegends-section-content-navigationMenu {
    height: 40px;
    padding: 2px 16px 2px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #00000014;
    border-radius: 4px;
}

.helpLegends-section-content-navigationMenu-logo {
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.helpLegends-section-content-navigationMenu-logo-title {
    margin-left: 4px;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 700;
}

.helpLegends-section-content-navigationMenu-display {
    padding-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #dee2e6;
}

.helpLegends-section-content-navigationMenu-display-course {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #C7C9CE;
    margin-right: 4px;
}

.helpLegends-section-content-navigationMenu-display-list {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #717884;
}

.helpLegends-section-content-controls-rectangle {
    padding: 5px;
    background: #71788414 0% 0% no-repeat padding-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.helpLegends-plus-minus-button {
    height: 30px;
    width: 30px;
    padding: 0px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px #71788429;
    border-color: #FFFFFF;
    border-radius: 4px;
    color: #21252A;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.helpLegends-zoom-value {
    margin-left: 8px;
    margin-right: 8px;
    color: #21252A;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.helpLegends-section-content-filter {
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.helpLegends-section-content-filter-label {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #71788466;
    border-radius: 18px;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #21252A;
    padding: 7px 16px 8px 16px;
    cursor: pointer;
}
.helpLegends-section-content-filter-label:hover {
    background: #E1E1E3 0% 0% no-repeat padding-box;
}

.helpLegends-section-content-layer {
    display: flex;
    flex-direction: column;
}

.helpLegends-section-content-layer-label {
    display: flex;
    align-items: center;
}
.helpLegends-section-content-layer-label:not(:last-child) {
    margin-bottom: 8px;
}

.helpLegends-section-content-layer-label-image {
    width: 50px;
    height: 50px;
    margin: 10px 10px;
    border-radius: 10px;
    text-align: center;
}
.helpLegends-section-content-layer-label-image img {
    width: 50px;
    height: 50px;
}

.helpLegends-section-content-layer-label-image:hover img {
    border: #0a58ca solid 2px;
    border-radius: 10px;
}
.helpLegends-section-content-layer-label-image:hover {
    cursor: pointer;
    color: #0a58ca;
    font-weight: bold;
}

.helpLegends-section-content-layer-label-text {
    padding-right: 16px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
}