.helpNotifUserMenu-content {
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;
    top: 8px;
    right: 8px;
    height: 56px;
    width: 160px;
    padding: 9px 12px 9px 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #00000014;
    border-radius: 4px;
    z-index: 100;
}

.helpNotifUserMenu-Btn:hover {
    cursor: pointer;
}