.admin-menu-content {
    position: fixed;
    display: flex;
    justify-content: space-around;
    align-items: center;
    top: 8px;
    right: 8px;
    height: 56px;
    padding: 9px 12px 9px 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #00000014;
    border-radius: 4px;
    z-index: 100;
}

.admin-menu-button {
    cursor: pointer;
}

.admin-menu-content-fixed {
    display: flex;
    width: 128px;
    justify-content: space-around;
    position: relative;
    align-items: center;
}

.admin-menu-content svg {
    height: 100%!important;
}

.admin-menu-content-context {
    position: relative;
    padding-right: 12px;
}

.admin-menu-content-context::after {
    top: 50%;
    right: 0;
    content: '';
    height: 100%;
    width: 0.5px;
    margin: 0px 8px;
    position: absolute;
    background-color: #dddddd;
    transform: translate(0px, -50%);
}

.admin-menu-button div {
    display: inline-block;
}

.admin-menu-content-teaching-unit-mode {
    display: inline-block;
    justify-content: center;
    margin-right: 15px;
    margin-left: 10px;
    margin-top: 2px;
    width: 35px;
    font-weight: 600;
    transform: translate(0px, 2px);
}
.admin-menu-content-teaching-unit-mode-draft {
    color: #6371B0;
}
.admin-menu-content-teaching-unit-mode-live {
    color: #77757F;
}

.context-menu-element {
    display: inline-block;
    margin-right: 15px;
}

.infoLink {
    position: absolute;
    right: 15px;
    cursor:pointer;
}

.teaching-unit-settings-section i {
    position: absolute;
    background-color: #fafafa;
    border: 3px dashed black;
    border-radius: 6px;
    right: 50px;
    width: 80%;
    padding: 15px;
    left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    min-height: 95%;
    display: none;
}

.teaching-unit-settings-section .infoLink:hover ~i{
    display: block;
}