.account-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 0;
    height: 100vh;
    display: block;
    right: 0;
    transition: all 0.5s ease-in-out;
    /* left: calc(100vw); */
    left: calc(100%);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px #71788429;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    z-index: 1000;
}
.account-modal.account-open {
    width: 350px;
    transition: all 0.5s ease-in-out;
    /* left: calc(100vw - 350px); */
    left: calc(100% - 350px);
}

.account-header {
    height: 112px;
    width: 350px;
    padding: 32px;
    border-bottom: 1px solid #dee2e6;
}

.account-title {
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 500;
    color: #21252A;
    margin-bottom: 0px;
}

.account-close {
    cursor: pointer;
    left: 300px;
    position: absolute;
    top: 25px;
    color: rgba(0, 0, 0, 0.36);
}

.account-body {
    width: 350px;
}

.account-section {
    height: 124px;
    padding: 50px 12px 50px 20px;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0px;
}
.account-section:hover {
    cursor: pointer;
}

.short-section {
    height: 60px;
    padding: 20px 12px 20px 20px;
}