.rocket_auth {
    animation: vibrate-1 0.3s linear infinite both;
    width: 175px;
    height: 175px;
    margin: 55px;
    position: absolute;
    top: -35px;
}

.loader_auth {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #3d3d3d;
    width: 250px;
    height: 250px;
    border-radius: 50%;
}

.bg-loader_auth {
    animation: topToBottom 0.7s linear infinite both;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) rotate(45deg);
}

.fox_logo {
    width: 220px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: scale-up-center 0.6s ease-in infinite alternate both;
}

@keyframes scale-up-center {
    0% {
        transform: translate(-50%, -50%) scale(1);
    }
    100% {
        transform: translate(-50%, -50%)scale(1.35);
    }
}

@keyframes rotate-scale-up {
    0% {
        transform: translate(-50%, -50%) scale(1) rotateZ(0);
    }
    25% {
        transform: translate(-50%, -50%) scale(1.5) rotateZ(180deg);
    }
    50% {
        transform: translate(-50%, -50%) scale(1) rotateZ(360deg);
    }

    100% {
        transform: translate(-50%, -50%) scale(1) rotateZ(360deg);
    }
}


@-webkit-keyframes vibrate-1 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
    20% {
        -webkit-transform: translate(-2px, 2px);
        transform: translate(-2px, 2px);
    }
    40% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }
    60% {
        -webkit-transform: translate(2px, 2px);
        transform: translate(2px, 2px);
    }
    80% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }
    100% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}
@keyframes vibrate-1 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
    20% {
        -webkit-transform: translate(-2px, 2px);
        transform: translate(-2px, 2px);
    }
    40% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }
    60% {
        -webkit-transform: translate(2px, 2px);
        transform: translate(2px, 2px);
    }
    80% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }
    100% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}

@keyframes topToBottom {
    0% {
        -webkit-transform: translate(-50%,-50%) rotate(45deg) translate(0px, -200px);
        transform: translate(-50%,-50%) rotate(45deg) translate(0px, -200px);
    }
    100% {
        -webkit-transform: translate(-50%,-50%) rotate(45deg) translate(0px, 550px);
        transform: translate(-50%,-50%) rotate(45deg) translate(0px, 550px);
    }
}

@-webkit-keyframes topToBottom {
    0% {
        -webkit-transform: translate(-50%,-50%) rotate(45deg) translate(0px, -200px);
        transform: translate(-50%,-50%) rotate(45deg) translate(0px, -200px);
    }
    100% {
        -webkit-transform: translate(-50%,-50%) rotate(45deg) translate(0px, 550px);
        transform: translate(-50%,-50%) rotate(45deg) translate(0px, 550px);
    }
}