.notification-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 0;
    height: 100vh;
    display: block;
    right: 0;
    transition: all 0.5s ease-in-out;
    /* left: calc(100vw); */
    left: calc(100%);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px #71788429;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    z-index: 1000;
}
.notification-modal.notification-open {
    width: 350px;
    transition: all 0.5s ease-in-out;
    /* left: calc(100vw - 350px); */
    left: calc(100% - 350px);
}

.notification-header {
    height: 64px;
    width: 286px;
    margin: 32px;
    border-bottom: 1px solid #dee2e6;
}

.notification-title {
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    font-size: 29px;
    font-weight: 500;
    color: #21252A;
    margin-bottom: 0px;
}

.notification-close {
    cursor: pointer;
    left: 300px;
    position: absolute;
    top: 25px;
    color: rgba(0, 0, 0, 0.36);
}

.notification-body {
    margin: 32px;
}