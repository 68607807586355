.layer_selector {
  position: absolute;
  bottom: 7px;
  left: 7px;
  float: right;
  background: white;
  width: 90px;
  height: 90px;
  border-radius: 5px;
}
.layer_selector .icon_text {
  font-size: 12px;
  color: white;
  position: absolute;
  margin-top: 70px;
  width: 90px;
  text-align: center;
  float: bottom;
  bottom: 10px;
}
.layer_selector .icon_image {
  margin: 5px;
  width: 80px;
  height: 80px;
}
.item_image img {
  width: 50px;
  height: 50px;
  margin: 5px 10px;
  border-radius: 10px;
}
.item_image {
  width: 100px;
  height: 100px;
  text-align: center;
}
.layer_item:hover img {
  border: #0a58ca solid 2px;
}
.layer_item:hover {
  color: #0a58ca;
  font-weight: bold;
}
.layer_list {
  background: white;
  width: 600px;
  height: 95px;
  position: absolute;
  bottom: 0;
  left: 90px;
  margin-left: 5px;
  border-radius: 10px;
  padding: 0;
}

@media(max-width:850px) {
  .layer_list  {
      width: 90px;
      height: 560px;
      bottom: 90px;
      left: 0;
      margin-bottom: 5px;
      margin-left: 0;
  }
}

.layer_item {
  cursor: pointer;
  display: inline-block;
  width: 100px;
  font-size: 8px;
  text-align: center;
  padding: 10px;
}

@media(max-width:850px) {
  .layer_item  {
    width: 90px;
    padding-left: 0;
    padding-right: 0;
  }
}
.hoverer {
  width: 110px;
  height: 95px;
  position: absolute;
  background: transparent;
  bottom: 0;
  z-index: -1;
}

.layer_item.selected img {
  border: #0a58ca solid 2px;
}
.layer_item.selected {
  color: #0a58ca;
  font-weight: bold;
}
