.sidebar-list-element {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2px 16px;
  color: #FFFFFF;
}

.sidebar-list-element-link {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px 10px;
  border-radius: 6px;
  font-weight: bold;
  text-transform: none;
  background-color: transparent;
  font-size: 0.8666666666666667rem;
  text-decoration: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
}
.sidebar-list-element-link:hover {
  background-color: #f2f5f9;
  color: #5569ff;
  transition: all 0.2s;
}
.sidebar-list-element-link-active {
  background-color: #f2f5f9;
  color: #5569ff;
}

.sidebar-list-element-link-logo {
  width: 26px;
  height: 26px;
  fill: white;
}
.sidebar-list-element-link:hover .sidebar-list-element-link-logo {
  fill: #5569ff;
  transition: all 0.2s;
}
.sidebar-list-element-link-logo-active {
  fill: #5569ff;
}

.sidebar-list-element-link-label {
  margin-left: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}