.objective-edit-pantel-contributory-objectives .rdt_TableHeader {
  color: #FC9F5B;
}

.objective-edit-pantel-contributory-objectives .rdt_TableHead {
  background-color: #FC9F5B;
}

.objective-edit-panel-conditional-objective-button {
  cursor: pointer;
}