.node .svg-node-icon{
  stroke: #717884;
  fill:#e1e1e3;
}

.node .link-fill {
    fill: #717884;
    stroke: none;
}

.node .circle {
    stroke: #717884;
    fill: #c7c9ce;
    stroke-width: 2;
}

.node .link-top {
    stroke: #717884;
}

.node .shield {
    fill: #c7c9ce;
    stroke: #717884;
}

.node.started .shield {
    fill: #5768ac;
    stroke: #2f4486;
}

.node.started  {
    fill: #5768ac;
    stroke: #2f4486;
}
.node.started  text {
    fill: white;
    stroke: none;
}

.node .shield text {
    fill: white;
    stroke: #717884;
}

.node.started .shield text {
    fill: white;
    stroke: #2f4486;
}

.node .collapser.collapsed {
    stroke: white;
}

.node .collapser.collapsed circle {
    stroke: #3b62ff;
    fill: #3b62ff;
}

.node .collapser.opened {
    stroke: #717884;
}

.node .collapser.opened circle {
    stroke: #717884;
    fill: transparent;
}

.node.started .collapser.collapsed {
    stroke: #5768ac;
}

.node.started .collapser.collapsed circle {
    stroke: white;
    fill: white;
}

.node.started .collapser.opened {
    stroke: white;
}

.node.started .collapser.opened circle {
    stroke: white;
    fill: #5768ac;
}

.node.done .collapser.collapsed circle {
    stroke: white;
    fill: white;
}

.node.done .collapser.opened {
    stroke: white;
}

.node.done .collapser.opened circle {
    stroke: white;
    fill: transparent;
}


.node .topic {
    fill: #717884;
}

.node.done .topic {
    fill: white;
}

.node.started .topic {
    fill: white;
}


.node .node-box-activity {
    fill: white;
}
.node.started .node-box-activity {
    fill: #5768ac;
}


.node .node-box-topic {
    fill: white;
}

.node.started .node-box-topic {
    fill: #5768ac;
}

.node.started .svg-node-icon {
    stroke: #2F4486;
    fill: #b9c0dc;
}

.node.started .circle {
    stroke: #2F4486;
    fill: #8A96C5;
}

.node.started .svg-node-icon.shield {
    stroke: #2f4486;
    fill: #5768ac;
}

.node.done .svg-node-icon.shield{
  stroke: #1e9b7e;
  fill: #81BDB3;
 }

.node.done .node-box-activity {
    fill: #1e9b7e;
}

/*.node.done .node-box {*/
/*  fill:#1e9b7e;*/
/*}*/

.node.done .link-fill {
    fill: #15705B;
    stroke: none;
}

.node.done .svg-node-icon {
    stroke: #15705B;
    fill: #a7d1ca;
}

.node.done .circle {
    stroke: #15705B;
    fill: #81bdb3;
}

.node.done .my-progress-bar text {
    fill: #1e9b7e;
}

.node .my-progress-bar text {
    fill: white;
}

.node.started .my-progress-bar text {
    fill: #5768ac;
}


.node.done .shield text {
    fill: white;
    stroke: #1e9b7e;
}

.node.done .collapser {
    stroke: #1e9b7e;
}

.node.done .node-box-topic {
    fill: #1e9b7e;
}


.node.mine .svg-node-icon .shield {
    stroke: #1e9b7e;
    fill: #a7d1ca;
}

.node.mine .node-box-activity {
    fill: #ffc9c9;
}


.node.mine .svg-node-icon {
    stroke: #ff908e;
    fill: white;
}

.node.mine .circle {
    stroke: #ff908e;
    fill: #ffc9c9;
}

.node.mine .link-fill {
    fill: #ff908e;
    stroke: none;
}

.node.started .link-fill {
    fill: #2f4486;
    stroke: none;
}

.node.started .gp-text {

    fill: #1e9b7e;
    stroke: none;
}

.node.started .link-top {
    stroke: #2f4486;
}

.node.mine .shield text {
    fill: white;
    stroke: #ff908e;
}

.node.mine text {
    fill: #f08482;
    /*stroke:#ff908e;*/
}

.node.mine .collapser {
    stroke: #1e9b7e;
}

.node.mine .node-box-topic {
    fill: #ffc9c9;
}

.link {
    stroke: #717884;
}

.link.done {
    stroke: #1e9b7e;
}

.link.started {
    stroke: #2f4486;
}

.link.mine {
    stroke: #f08482;
}

.header.done {
    background: #1e9b7e;
}

.modal .header.started {
    background: #2f4486;
}

.header.activity.started {
    background: #2f4486;
}

.modal .header.done {
    background: #1e9b7e;
}
.modal .header.activity.done {
    background: #1e9b7e;
}

.linkActivityToObjectif
{
    opacity:0.4;
}


.linkObjectifToObjectif {
    opacity:0.15;
}

.arrow-linkObjectifToObjectif {
    opacity: 0.2!important;
}

.arrow-current {
    opacity: 0.2;
}

.link-selected {
    opacity: 1;
}

.arrow-selected {
    opacity: 1!important;
}

.arrow:not(.arrow-current) {
    /*transition: all 0.2s;*/
    opacity:0;
}

.link:not(.link-current) {
    /*transition: all 0.2s;*/
    opacity:0;
}

.node_activite:not(.activite_current) {
    /*transition: all 0.2s;*/
    visibility: hidden;
}

.node_aggregator:not(.aggregator_current){
    /*transition: all 0.2s;*/
    visibility: hidden;
}

.node_objectif:not(.objectif_current) {
    /*transition: all 0.2s;*/
    opacity:0.4;
}

.node_not_active {
    /*transition: all 0.2s;*/
    visibility: hidden!important;
}

.link_not_active {
    /*transition: all 0.2s;*/
    visibility: hidden!important;
}

.arrow_not_active {
    /*transition: all 0.2s;*/
    visibility: hidden!important;
}

.objectif_required rect {
    stroke-width: 7px;
}

.objectif_required.todo rect {
    stroke: #5e5e5e;
}

.objectif_required.done rect {
    stroke:#027b5f;
}

.objectif_required.started rect {
    stroke:#313a5b;
}

/*.node_objectif:not(.objectif_current) rect {
    transition: all 0.2s;
    opacity:0.4;
}*/

.activite_required .circle {
    stroke-width: 4px;
}

.node_aggregator {

}

.shield {
    /*transition:all 0.2s;*/
}

.header g.svgIcon.topic {
transform: translate(235px, 0px);
}

/*.node.selected .selected-highlight{*/
/*    stroke :white;*/
/*}*/
.node.selected {
    -webkit-filter: drop-shadow( 0px 0px 5px rgb(59, 98, 255));
    filter:         drop-shadow( 0px 0px 5px rgb(59, 98, 255));
}