.teaching-units-index {
  position: absolute;
  display: flex;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  background-color: #F0F0F3 ;
  z-index: 9;
}

.teaching-units-index-title {
  padding-left: 40px;
  margin: 16px 16px 0px 16px;
  color: #6371B0;
  font-size: 1.8em;
  font-weight: 600;
}

.teaching-units-index-editable-cell {
  width: 100%;
  max-width: 1000px;
  padding: 4px;
  outline: none;
  border: none;
  border-bottom: solid 1px #dfdfdf;
  background-color: inherit;
}
.teaching-units-index-editable-cell:focus, .teaching-units-index-editable-cell:hover {
  outline: none;
  border-bottom: solid 1px #6371B0;
}

.teaching-units-index-link {
  margin: 0px 8px;
  cursor: pointer;
}

.teaching-units-index-link svg, .teaching-units-index-link path {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.teaching-units-index-link svg:hover, .teaching-units-index-link svg:hover path {
  fill:#5569ff;
  transition: all 0.2s;
}
.teaching-units-index-link-activated svg {
  fill:#5569ff;
}

.teaching-units-index-existing-logo {
  color: green;
  font-size: large;
  font-weight: bold;
}

.teaching-units-index-unexisting-logo {
  color: red;
  font-size: large;
  font-weight: bold;
}


.teaching-units-index-table {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  width: 100%;
  padding: 1rem;
}

.teaching-units-index-table .data-table-extensions {
  display: inline-block;
  width: 25%;
  padding: 0.7rem 1.2rem;
  box-sizing: border-box;
}

.teaching-units-index-table .data-table-extensions-filter {
  float: left;
  position: relative;
  display: flex;
}

.teaching-units-index-table .data-table-extensions-filter .filter-text {
  padding: 4px;
  border: none;
  border: 0;
  border-bottom: solid 1px #dfdfdf;
  margin-left: 4px;
  outline: none;
  background-color: transparent;
}
.teaching-units-index-table .data-table-extensions-filter > .filter-text:focus, .teaching-units-index-table .data-table-extensions-filter > .filter-text:hover {
  border-bottom-color: #6371B0;
}

@media(max-width: 1065px) {
  .teaching-units-index-table .data-table-extensions-filter .filter-text {
    width: 150px;
  }
}
@media(min-width: 1065px) {
  .teaching-units-index-table .data-table-extensions-filter .filter-text {
    width: 190px;
  }
}
@media(min-width: 1800px) {
  .teaching-units-index-table .data-table-extensions-filter .filter-text {
    width: 300px;
  }
}

.teaching-units-index-table .data-table-extensions-filter > .icon {
  display: block;
  float: left;
  width: 20px;
  height: 24px;
  margin-top: 4px;
  fill: #6371B0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAB80lEQVQ4T73Uv2tTURQH8O85972kxpqWNG2gOKhb21X8hXYQxCEKLp3cXNw6OIm0zTWWVjo4Cv4BIlIEF4c6FEQQi4uLZNTFYvNDiwWN9713jrxKQZOXBNLiXd89H77n3Hcv4YAXHbCH/wuOWh3kKJhSEDvjVbYtbffqKDFhrqRZ1ui+IrrB4PQfRCIieorAv1Vdoq1OcBsYY4jCV6Q0aAxuS9O8TGUQhRJeUGBZoTmQf65+lzaT0DYwvxA+1AiXBsSc+bxMjb+Lxq1mnITrpFKr3Utf7QnGMxNxdQNzvVr2niUVjFh3ioU2WMITW4uHPrbu+SdhYd6dFqK32vSy9RXaSZ6T0uiCawDmZq3srXYFx+aDs0p447N3eNPSj06Dz881a2Bvtl72nnQFh60O++LqBC5Wy/5aEpizvyaN8AcRmWwspitdwfjjWMk9VqUJn73zbSln1IxMuBcMztTK/nTPQ9kF72hBvGADoC/KOtuw/juANE5GggcMvkyq6xnjFz9ZavZMGG/Il3ScxD0C8RVAvqloRGzyAF6TaqBEFwWydoRT11rRrne5MPfzuLB/UgFPJXofz+yY1YEdcc/jpEloX49DK9qopIpYpSjuri8wLtxDAUwHnDr63dLXfYG7hzGjJjuFoT1s/2DCf9N3y51u0W9RndcVBJTNUgAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: left center;
}

.teaching-units-index-table .rdt_TableHeader {
  width: 60%;
  background-color: inherit;
  color: #6371B0;
}

.teaching-units-index-table .rdt_Table {
  border-radius: 2px;
}

.teaching-units-index-table .rdt_TableHead {
  color: #FFFFFF;
  background-color: #6371B0;
}

.teaching-units-index-table .rdt_TableHeadRow {
  background-color: inherit;
}

.teaching-units-index-table .rdt_Pagination {
  background-color: inherit;
}

.teaching-units-index-table > div:last-child {
  width: 100%;
}

.teaching-units-index-loader {
  position: relative;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
  overflow-x: auto;
  overflow-y: hidden;
}