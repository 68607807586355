.activityFilter {
    position: absolute;
    top: 72px;
    left: 8px;
    display: flex;
    justify-content: space-between;
}

@media(max-width:650px) {
    .activityFilter  {
        height: 124px;
        flex-direction: column;
    }
}

.activityFilterList {
    margin-right: 10px;
}

.not-selectable .activityFilterLabel,.not-selectable .activityFilterSelectModal  {
    background-color: #E1E1E3;
    opacity: 0.5;
    cursor: not-allowed!important;
}

.not-selectable .activityFilterSelect {
    background-color: #E1E1E3;
    opacity: 0.5;
    cursor: not-allowed!important;
}

.activityFilterLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #71788466;
    border-radius: 18px;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #21252A;
    padding: 7px 16px 8px 16px;
    cursor: pointer;
}
.activityFilterLabelClicked {
    background: #E8F0FE 0% 0% no-repeat padding-box;
    border: 1px solid #D2E3FC;
    color: #3B62FF;
}
.activityFilterLabelNoCLicked {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #71788466;
    color: #21252A;
}
.activityFilterLabel:hover {
    background: #E1E1E3 0% 0% no-repeat padding-box;
}
.activityFilterLabelType {
    width: 160px;
}
.activityFilterLabelState {
    width: 185px;
}
.activityFilterLabelTime {
    width: 190px;
}

.bi-caret-down-fill {
    margin-left: 8px;
}

.activityFilterSelectModal {
    display: none;
    position: absolute;
    top: 44px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #00000014;
    border-radius: 4px;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #21252A;
}
.activityFilterSelectModal-open {
    display: block;
}

@media(max-width:650px) {
    .activityFilterSelectModal  {
        top: 134px;
    }
}

.activityFilterSelect {
    cursor: pointer;
    padding-left: 14px 12px 12px;
    padding-left: 12px;
    padding-right: 32px;
}
.activityFilterSelect:first-of-type {
    margin-top: 14px;
}
.activityFilterSelect:last-of-type {
    margin-bottom: 12px;
}
.activityFilterSelect:hover {
    background: #71788429;
}
